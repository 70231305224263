import _isEmptyArray from "is-empty-array";
import _isEmptyObject from "is-empty-object";
var exports = {};

/**
 * Dependencies
 */
var isEmptyArray = _isEmptyArray;
var isEmptyObject = _isEmptyObject;
/**
 * Safely remove empty values from `arr`.
 *
 * @param  {array} arr
 * @return {array}
 *
 * @api public
 */

function cleanArray(arr) {
  var results = arr.filter(function (item) {
    if (isEmptyArray(item) || isEmptyObject(item)) return;
    return item || item === 0 || item === false;
  });
  return results;
}
/**
 * Exports
 */


exports = cleanArray;
export default exports;